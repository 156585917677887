export const impressumItems = [
  {
    id: 1,
    company_name: "DotRoll Kft.",
    company_based: "1148 Budapest, Fogarasi út 3-5.",
    company_letter_address: "1148 Budapest, Fogarasi út 3-5.",
    company_company_registration_number: "01-09-882068",
    company_tax_number: "13962982-2-42",
    company_email: "support@dotroll.com",
    company_phone: "(+36) 1 432 3232",
  },
  {
    id: 2,
    company_name: "Netlify Inc.",
    company_based: "610 22nd St #315, San Francisco",
    company_letter_address: "610 22nd St #315, San Francisco",
    company_email: "support@netlify.com",
  }
]