import "./Footer.scss";
import logo from "../../assets/images/logo/koltozzolcson_logo_full_nobuffer_trans_white.png";
import facebookLogo from "../../assets/icons/footer/facebook.png";
import tiktokLogo from "../../assets/icons/footer/tiktok.png";
import { CONTACT } from "../../contactInfo";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div className="outer-div">
      <footer className="footer-container">
        <div className=" footer-grid grid">
          <div className="logo-col">
            <a href="/" className="footer-logo">
              <img
                src={logo}
                alt="költözz olcsón logo"
                className="footer-logo-img"
              />
            </a>
            <div className="footer-socials-row">
              <ul className="footer-socials">
                <li>
                  <a
                    href="https://www.facebook.com"
                    className="footer-link"
                    aria-label="Költözz olcsón facebook oldal"
                  >
                    <img
                      src={facebookLogo}
                      className="facebook-logo footer-socials-icon"
                      alt="facebook logó"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.tiktok.com"
                    className="footer-link"
                    aria-label="Költözz olcsón tiktok oldal"
                  >
                    <img
                      src={tiktokLogo}
                      className="tiktok-logo footer-socials-icon"
                      alt="tiktok logó"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="sites-col">
            <p className="footer-heading">Oldalak</p>
            <ul className="footer-list sites-list">
              <li>
                <a href="/rolunk" className="footer-link">
                  Rólunk
                </a>
              </li>
              <li>
                <a href="/referenciak" className="footer-link">
                  Referenciák
                </a>
              </li>
              <li>
                <a href="/arak" className="footer-link">
                  Árak
                </a>
              </li>
              <li>
                <a href="/blog" className="footer-link">
                  Blog
                </a>
              </li>
              <li>
                <a href="/kapcsolat" className="footer-link">
                  Kapcsolat
                </a>
              </li>
            </ul>
          </div>
          <div className="services-col">
            <p className="footer-heading">Szolgáltatások</p>
            <ul className="footer-list services-list">
              <li>
                <a href="/szolgaltatasok/lakossagi" className="footer-link">
                  Lakossági költöztetés
                </a>
              </li>
              <li>
                <a href="/szolgaltatasok/uzleti" className="footer-link">
                  Céges költöztetés
                </a>
              </li>
              <li>
                <a href="/szolgaltatasok/lomtalanitas" className="footer-link">
                  Lomtalanítás
                </a>
              </li>
            </ul>
          </div>{" "}
        </div>
        <div className="footer-contact-row">
          <p>{CONTACT.ADDRESS}</p>
          <p>
            <a href={`tel:${CONTACT.PHONELINK}`}>{CONTACT.PHONE}</a>
          </p>
          <p>
            <a href={`mailto:${CONTACT.EMAIL}`}>{CONTACT.EMAIL}</a>
          </p>
          <p>Nyitvatartás: 0-24 óráig</p>
        </div>
        <div className="docs-row">
          <ul className="footer-docs-links">
            <li>
              <a
                className="footer-link"
                href="/assets/koltozzolcson_adatkezelesi_tajekoztato_240827.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Adatvédelmi tájékoztató
              </a>
            </li>
            {/* <li>
              <a
                className="footer-link"
                href="/"
                // href="/assets/koltozzolcson_aszf.pdf"
                target="_blank"
                rel="noreferrer"
              >
                ÁSZF
              </a>
            </li> */}
            <li>
              <a className="footer-link" href="/impresszum">
                Impresszum
              </a>
            </li>
          </ul>
        </div>
        <div className="copyright-row">
          <p className="copyright-text">
            &copy; {year} {CONTACT.COMPANYNAME} Minden jog fenntartva.{" "}
            {/* </p>
        <p className="copyright-text">
          {" "} */}
            A weboldalon található ikonok a{" "}
            <a href="https://www.flaticon.com" target="_blank" rel="noreferrer">
              Flaticon
            </a>{" "}
            oldalról származnak.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
