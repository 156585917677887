import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./HomeHero.scss";
import heroPic1 from "../../../assets/images/home/homehero/homeheropic.webp";
import heroPic2 from "../../../assets/images/home/homehero/homeheropic2.webp";
import heroPic3 from "../../../assets/images/home/homehero/homeheropic3.webp";

const homeHeroContent = [
  {
    title: ["Zsebbarát költöztetés"],
    description:
      "Úgy gondoljuk, hogy a költözés nem csak egy stresszes időszak lehet, hanem egy új élet kezdete is.",
    pic: heroPic1,
    picAlt: "Országos lefedettségű költöztetés azonnal a legjobb áron",
    buttonText: "Árajánlatot kérek!",
    secondaryButtonText: "Kérdésem van!",
  },
  {
    title: ["Költöztetés rejtett költségek nélkül"],
    description:
      "Országos lefedettséggel vállalunk költöztetést magánszemélyeknek és cégeknek egyaránt.",
    pic: heroPic2,
    picAlt: "Országos lefedettségű költöztetés azonnal a legjobb áron",
    buttonText: "Árajánlatot kérek!",
    secondaryButtonText: "Kérdésem van!",
  },
  {
    title: ["Költöztetés már", "44.990 Ft-tól"],
    description: "Országos lefedettséggel vállalunk költöztetést.",
    pic: heroPic3,
    picAlt: "Országos lefedettségű költöztetés azonnal a legjobb áron",
    buttonText: "Árajánlatot kérek!",
    secondaryButtonText: "Kérdésem van!",
  },
];

const HomeHero = () => {
  return (
    <div className="navbar-margin-top hero-container">
      <Carousel fade>
        {homeHeroContent.map((content, index) => (
          <Carousel.Item key={index} className="carousel-item">
            <img
              className="d-block w-100 hero-img"
              src={content.pic}
              alt={content.picAlt}
            />
            <Carousel.Caption className="hero-img-text-box">
              <h3 className="hero-img-title">
                {content.title.map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    {index < content.title.length - 1 && <br />}
                  </React.Fragment>
                ))}
              </h3>
              <p className="hero-img-description">{content.description}</p>
              <div className="hero-img-buttons_wrapper">
                <a
                  href={"/ajanlat"}
                  className="hero-img-button regular-button btn"
                >
                  {content.buttonText}
                </a>
                <a
                  href={`/kapcsolat`}
                  className="hero-img-button secondary-button btn"
                >
                  {content.secondaryButtonText}
                </a>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default HomeHero;
