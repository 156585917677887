import Marquee from "react-fast-marquee";
import "./HomeReferencies.scss";
import skytecLogo from "../../../assets/images/home/homereferencies/skytec.png";
import szarvasvedLogo from "../../../assets/images/home/homereferencies/szarvasved.png";
import visiontrendLogo from "../../../assets/images/home/homereferencies/visiontrend.png";
import anLogo from "../../../assets/images/home/homereferencies/an.png";

const HomeReferencies = () => {
  return (
    <div className="container home-referencies-container">
      <h2 className="section__title">Akikkel már korábban együtt dolgoztunk</h2>
      <Marquee pauseOnHover loop={0} autoFill={true}>
        <img src={skytecLogo} alt="Skytec logó" className="home-referencies-logo" />
        <img
          src={szarvasvedLogo}
          alt="Szarvasvéd logó"
          className="home-referencies-logo"
        />
        <img src={visiontrendLogo} alt="VisionTrend logó" className="home-referencies-logo" />
        <img
          src={anLogo}
          alt="AN logó"
          className="home-referencies-logo"
        />
        {/* <img src={logo6} alt="la fer car logó" className="home-partners-logo" />
        <img
          src={logo7}
          alt="magyar autóklub logó"
          className="home-partners-logo"
        />
        <img src={logo8} alt="mercarius logó" className="home-partners-logo" />
        <img src={logo9} alt="schiller logó" className="home-partners-logo" /> */}
      </Marquee>
    </div>
  );
};

export default HomeReferencies;
