import React, { useState } from "react";
import "./Services.scss";
import { Accordion } from "react-bootstrap";
import PageTitle from "../Shared/PageTitle/PageTitle";
import {
  servicesGarbageCollectionServiceContainsData,
  servicesGarbageCollectionAccordionData,
  servicesGarbageCollectionMovingTipsData,
} from "./servicesData";
import ListContainer from "../Shared/ListContainer/ListContainer";
import CTAPor from "../Shared/CTAPor/CTAPor";

const ServicesGarbage = () => {
  const [openCardId, setOpenCardId] = useState(null);

  const handleMoreInfoClick = (listType, cardId) => {
    const uniqueId = `${listType}-${cardId}`;
    setOpenCardId((prevId) => (prevId === uniqueId ? null : uniqueId));
  };

  return (
    <>
      <PageTitle content="Hulladék elszállítás" backgroundColor="#f5f5f5" />
      <div className="container services-container services-garbage-container">
        <div className="services-wrapper services-garbage-wrapper">
          <div className="services-intro services-garbage-intro">
            <p className="services-intro-text">
              A hulladék elszállítás során kiemelt figyelmet fordítunk arra,
              hogy a környezetvédelmi szabályoknak megfelelően, biztonságosan és
              környezetbarát módon szállítsuk el a hulladékot. Célunk, hogy a
              hulladék elszállítás a lehető legkisebb hatással legyen a
              környezetre, ezért a hulladékot szelektíven gyűjtjük és megfelelő
              módon ártalmatlanítjuk.
            </p>
          </div>
          <ListContainer
            title="Mit tartalmaz hulladék elszállítási szolgáltatásunk?"
            data={servicesGarbageCollectionServiceContainsData}
            openCardId={openCardId}
            handleMoreInfoClick={handleMoreInfoClick}
            listType="services"
          />

          <CTAPor />

          <div className="faq-container">
            <h2 className="faq-header">Gyakran ismételt kérdések</h2>
            <div className="faq-accordion-container">
              <Accordion defaultActiveKey={["0"]} alwaysOpen>
                {servicesGarbageCollectionAccordionData.map((item) => (
                  <Accordion.Item eventKey={item.id.toString()} key={item.id}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body>{item.content}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>

          <div className="services-tips">
            <h2 className="services-tips-title">
              Tippek és javaslatok a legkellemesebb Lomtalanításhoz
            </h2>
            <p className="services-tips-intro">
              A lomtalanítás gyakran egy nagyobb takarítási projekt része,
              legyen szó lakásról, irodáról vagy akár egy raktárról. Egy kis
              előkészítéssel hatékonyabbá és környezetbarátabbá tehetjük ezt a
              folyamatot. Az alábbi tippek segíthetnek abban, hogy a
              lomtalanítás gyorsan és zökkenőmentesen történjen.
            </p>
            <div className="tip-item">
              {servicesGarbageCollectionMovingTipsData.map((tip) => (
                <div key={tip.id} className="tip-item">
                  <h2>{tip.itemTitle}</h2>
                  <p>{tip.itemIntro}</p>
                  {tip.itemList && (
                    <ul>
                      {tip.itemList.map((item, index) => (
                        <li key={index}>
                          <h3>{item.itemListTitle}</h3>
                          <p>{item.itemListContent}</p>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesGarbage;
