import "./About.scss";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import PageTitle from "../Shared/PageTitle/PageTitle";
import CTAPor from "../Shared/CTAPor/CTAPor";
import proIcon from "../../assets/icons/about/star.png";
import reliableIcon from "../../assets/icons/about/checked.png";
import flexibleIcon from "../../assets/icons/about/time.png";

const About = () => {
  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <>
      <PageTitle content="Rólunk" backgroundColor="#f4ece0" />
      <div className="container about-container">
        <div className="about-intro">
          {/*  <div className="about-intro-img-wrapper">
           {screenWidth < 768 && (
              <img
                src={dominikPic}
                alt="Hegyi Dominik"
                className="about-intro-img"
              />
            )}
            {screenWidth >= 768 && (
              <img
                src={dominikPic}
               alt="Hegyi Dominik"
                className="about-intro-img"
              />
            )}
            <div className="about-intro-img-caption">
              <h3>Hegyi Dominik</h3>
              <p>Tulajdonos</p>
            </div>
          </div> */}
          <div className="about-intro-text">
            <p>
              Cégünk egy dinamikus, fiatalos csapatból áll, akik a költöztetés
              minden lépését profi módon kezelik. Szenvedélyünk a költöztetés,
              és célunk, hogy minden ügyfelünknek a lehető legkényelmesebb és
              legstresszmentesebb élményt nyújtsuk.
            </p>
            <p>
              Minden feladatot maximális odafigyeléssel és precizitással végzünk
              el, legyen szó lakásköltöztetésről, irodaköltöztetésről vagy akár
              egyedi szállítási igényekről. Rugalmasak vagyunk, alkalmazkodunk
              az egyedi igényekhez és határidőkhöz, így biztos lehet benne, hogy
              mindig számíthat ránk.
            </p>
            <p>
              Munkánkat a megbízhatóság és a profizmus jellemzi, ezért
              ügyfeleink elégedettsége számunkra az első helyen áll. Modern
              felszereléseinkkel és tapasztalt kollégáinkkal garantáljuk, hogy
              értékei biztonságban és épségben érkeznek meg új otthonukba.
            </p>
            <p>
              Válasszon minket, és tapasztalja meg, hogy a költözés is lehet
              egyszerű és zökkenőmentes!
            </p>
          </div>
        </div>
        <div className="about-why-us">
          <h2>Miért minket válasszon?</h2>
          <div className="about-why-us-grid">
            <motion.div
              className="about-why-us-item"
              ref={ref1}
              initial={{ opacity: 0, y: 20 }}
              animate={inView1 ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 1 }}
            >
              <motion.img
                src={proIcon}
                alt="why-us"
                className="about-why-us-img"
              />{" "}
              <div className="why-us-text-wrapper">
                <h3>Profizmus</h3>
                <p>
                  Tapasztalt, jó fizikummal rendelkező kollégáink minden esetben
                  profi módon kezelik a költöztetést, és a gyorsaság az egyik
                  legfontosabb szempont számunkra.
                </p>
              </div>
            </motion.div>
            <motion.div
              className="about-why-us-item"
              ref={ref2}
              initial={{ opacity: 0, y: 20 }}
              animate={inView2 ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 1 }}
            >
              <motion.img
                src={reliableIcon}
                alt="why-us"
                className="about-why-us-img"
              />
              <div className="why-us-text-wrapper">
                <h3>Megbízhatóság</h3>
                <p>
                  Minden munkánkat precízen és megbízhatóan végezzük el, így
                  biztos lehet benne, hogy értékei biztonságban érkeznek meg új
                  otthonukba.
                </p>
              </div>
            </motion.div>
            <motion.div
              className="about-why-us-item"
              ref={ref3}
              initial={{ opacity: 0, y: 20 }}
              animate={inView3 ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 1 }}
            >
              <motion.img
                src={flexibleIcon}
                alt="why-us"
                className="about-why-us-img"
              />
              <div className="why-us-text-wrapper">
                <h3>Rugalmas megoldások</h3>
                <p>
                  Az egyedi igényekhez és határidőkhöz rugalmasan
                  alkalmazkodunk, így biztos lehet benne, hogy mindig számíthat
                  ránk.
                </p>
              </div>
            </motion.div>
          </div>
        </div>
        <CTAPor />
      </div>
    </>
  );
};

export default About;
