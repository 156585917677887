import { Accordion } from "react-bootstrap";
import "./FreqAskQuestions.scss";

const FreqAskQuestions = () => {
  return (
    <div className="faq-container">
      <h1 className="faq-header">Gyakran ismételt kérdések</h1>
      <div className="faq-accordion-container">
        <Accordion defaultActiveKey={["0"]} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Hogyan tudok fizetni?</Accordion.Header>
            <Accordion.Body>
              A helyszínen természetesen kártyával és készpénzzel is fizethet, illetve utalással.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              A szállításra vállalnak garanciát?
            </Accordion.Header>
            <Accordion.Body>
              Igen, a szállításra is garanciát vállalunk, a szállítás során
              keletkezett károkért felelősséget vállalunk.
            </Accordion.Body>
          </Accordion.Item>{" "}
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Az oldalon tudok árat kalkulálni?
            </Accordion.Header>
            <Accordion.Body>
              Sajnos nem, hiszen minden eset egyedi, és nem szeretnénk senkit
              sem meglepni, ezért minden esetben személyre szabott árat adunk.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default FreqAskQuestions;
