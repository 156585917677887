import React, { useState } from "react";
import "./Services.scss";
import { Accordion } from "react-bootstrap";
import PageTitle from "../Shared/PageTitle/PageTitle";
import {
  servicesBusinessServiceContainsData,
  servicesBusinessAccordionData,
  servicesBusinessMovingTipsData,
} from "./servicesData";
import ListContainer from "../Shared/ListContainer/ListContainer";
import CTAPor from "../Shared/CTAPor/CTAPor";

const ServicesCompany = () => {
  const [openCardId, setOpenCardId] = useState(null);

  const handleMoreInfoClick = (listType, cardId) => {
    const uniqueId = `${listType}-${cardId}`;
    setOpenCardId((prevId) => (prevId === uniqueId ? null : uniqueId));
  };

  return (
    <>
      <PageTitle content="Céges költöztetés" backgroundColor="#f0f5ff"/>
      <div className="container services-container services-company-container">
        <div className="services-wrapper services-company-wrapper">
          <div className="services-intro services-company-intro">
            <p className="services-intro-text">
              A céges költöztetés speciális figyelmet igényel, hiszen nem csak a
              bútorokat kell szállítani, hanem irodai eszközöket, dokumentumokat
              és érzékeny adatokat is. Célunk, hogy a költözés a lehető
              legkisebb hatással legyen a cég mindennapi működésére, ezért
              rugalmasan alkalmazkodunk az Ön időbeosztásához, akár éjszakai
              vagy hétvégi költöztetéssel is.
            </p>
          </div>
          <ListContainer
            title="Mit tartalmaz üzleti szolgáltatásunk?"
            data={servicesBusinessServiceContainsData}
            openCardId={openCardId}
            handleMoreInfoClick={handleMoreInfoClick}
            listType="services"
          />

          <CTAPor />

          <div className="faq-container">
            <h2 className="faq-header">Gyakran ismételt kérdések</h2>
            <div className="faq-accordion-container">
              <Accordion defaultActiveKey={["0"]} alwaysOpen>
                {servicesBusinessAccordionData.map((item) => (
                  <Accordion.Item eventKey={item.id.toString()} key={item.id}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body>{item.content}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>

          <div className="services-tips">
            <h2 className="services-tips-title">
              Pár tipp, hogy könnyebben készülhessen vállalkozásának
              költöztetéséhez
            </h2>
            <p className="services-tips-intro">
              A céges költözés különös előkészítést igényel, hiszen nem csak a
              bútorokat, hanem az irodai eszközöket, dokumentumokat és egyéb
              fontos eszközöket is költöztetni kell. A folyamat gördülékenysége
              nagyban függ a tervezéstől és a kommunikációtól. Íme néhány tipp,
              hogy minden zökkenőmentesen menjen:
            </p>
            <div className="tip-item">
              {servicesBusinessMovingTipsData.map((tip) => (
                <div key={tip.id} className="tip-item">
                  <h2>{tip.itemTitle}</h2>
                  <p>{tip.itemIntro}</p>
                  {tip.itemList && (
                    <ul>
                      {tip.itemList.map((item, index) => (
                        <li key={index}>
                          <h3>{item.itemListTitle}</h3>
                          <p>{item.itemListContent}</p>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesCompany;
