import React, { useState } from "react";
import "./Prices.scss";
import { motion } from "framer-motion";
import PageTitle from "../Shared/PageTitle/PageTitle";
import CTAPor from "../Shared/CTAPor/CTAPor";
//icons
import oradijIcon from "../../assets/icons/prices/alarm.png";
import csomagoloanyagokIcon from "../../assets/icons/prices/open-box.png";
import szakertoiCsapatIcon from "../../assets/icons/prices/group.png";
import biztositasIcon from "../../assets/icons/prices/checked.png";
import tavolsagIcon from "../../assets/icons/prices/distance.png";
import tipusIcon from "../../assets/icons/prices/cart.png";
import mennyisegIcon from "../../assets/icons/prices/shipping.png";
import extraIcon from "../../assets/icons/prices/handle.png";
import "../Shared/ListContainer/ListContainer.scss";
import ListContainer from "../Shared/ListContainer/ListContainer";

const pricesContainData = [
  {
    id: 1,
    title: "Hatékony ütemezés",
    pricesExtendedText: `Szolgáltatásaink hatékony ütemezést biztosítanak, hogy a költözés zökkenőmentesen és időben történjen. Rugalmas időpontokat kínálunk, hogy megfeleljünk az Ön igényeinek.`,
    img: oradijIcon,
    imgAlt: "Hatékony ütemezés",
  },
  {
    id: 2,
    title: "Csomagolóanyagok igény szerint",
    pricesExtendedText: `Áraink tartalmazhatják a szükséges csomagolóanyagokat is, mint dobozok, fóliák és ragasztószalagok. Kérésre biztosítjuk ezeket, így Önnek nem kell külön gondoskodnia róluk.`,
    img: csomagoloanyagokIcon,
    imgAlt: "Csomagolóanyagok",
  },
  {
    id: 3,
    title: "Szakértői csapat",
    pricesExtendedText: `Az áraink tartalmazzák a csapat munkadíját és a jármű költségeit is.`,
    img: szakertoiCsapatIcon,
    imgAlt: "Szakértői csapat",
  },
  {
    id: 4,
    title: "Biztosítás",
    pricesExtendedText: `Minden költözéshez alap biztosítást kínálunk, hogy Önnek ne kelljen aggódnia az esetleges sérülések miatt. További biztosítási opciók is elérhetők, ha nagy értékű tárgyak szállításáról van szó.`,
    img: biztositasIcon,
    imgAlt: "Biztosítás",
  },
];

const pricesEffectData = [
  {
    id: 1,
    title: "Költözés távolsága",
    pricesExtendedText: `A költözés távolsága befolyásolja a költségeket, legyen szó helyi vagy hosszú távú költözésről.`,
    img: tavolsagIcon,
    imgAlt: "Költözés távolsága",
  },
  {
    id: 2,
    title: "Költözés típusa",
    pricesExtendedText: `A költözés típusa is hatással van az árra. Például egy irodai költözés ára eltérhet egy lakóköltözés árától.`,
    img: tipusIcon,
    imgAlt: "Költözés típusa",
  },
  {
    id: 3,
    title: "Tárgyak mennyisége",
    pricesExtendedText: `A szállítandó tárgyak mennyisége és súlya szintén hatással van az árra.`,
    img: mennyisegIcon,
    imgAlt: "Tárgyak mennyisége",
  },
  {
    id: 4,
    title: "Extra szolgáltatások",
    pricesExtendedText: `Ha extra szolgáltatásokra van szüksége, például bútorszerelésre vagy speciális szállításra, ezek külön díjazással járhatnak.`,
    img: extraIcon,
    imgAlt: "Extra szolgáltatások",
  },
];

const Prices = () => {
  const [openCardId, setOpenCardId] = useState(null);

  const handleMoreInfoClick = (listType, id) => {
    const newOpenCardId = `${listType}-${id}`;
    setOpenCardId(openCardId === newOpenCardId ? null : newOpenCardId);
  };

  return (
    <>
      <PageTitle content="Árak" backgroundColor="#f4ece0" />
      <div className="container prices-container">
        <div className="prices-wrapper">
          <div className="prices-card-wrapper">
            <motion.div
              className="prices-card"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <div className="prices-card-header">
                <h2>Költöztetés már</h2>{" "}
                <h1>
                  44990 Ft<span>/tól</span>
                </h1>
              </div>
              <div className="prices-card-body">
                <ul className="prices-list">
                  <li>
                    <p>
                     44990 Ft/alkalom kezdőáron elérhető szolgáltatásunk
                    </p>
                  </li>
                  <li>
                    <p>
                      Rejtett költségek nélkül - az ár 3 órát, 2 főt illetve a
                      járművet foglalja magában
                    </p>
                  </li>
                  <li>
                    <p>
                      Rugalmas megoldás, amely lehetővé teszi, hogy csak az
                      igénybe vett időt fizesse
                    </p>
                  </li>
                  <li>
                    <p>
                      Elégedettségi garancia - ha nem elégedett, visszatérítjük
                      a díjat
                    </p>
                  </li>
                </ul>
              </div>
              <div className="prices-card-footer">
                <motion.button
                  className="prices-card-button"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => { window.location.href = "/ajanlat"; }}
                >
                  Kérek ajánlatot!
                </motion.button>
              </div>
            </motion.div>
          </div>
          <p className="prices-intro-text">
            Tudjuk, hogy a költözés rengeteg kihívással járhat, ezért célunk,
            hogy szolgáltatásainkat átlátható és versenyképes áron kínáljuk
            Önnek. Hiszünk abban, hogy minden ügyfelünk egyedi, ezért árainkat
            úgy alakítottuk ki, hogy igazodjanak az Ön igényeihez és
            költözésének részleteihez.
          </p>
          <ListContainer
            title="Mit tartalmaznak áraink?"
            data={pricesContainData}
            openCardId={openCardId}
            handleMoreInfoClick={handleMoreInfoClick}
            listType="contain"
          />

          <ListContainer
            title="Milyen tényezők befolyásolják az árat?"
            data={pricesEffectData}
            openCardId={openCardId}
            handleMoreInfoClick={handleMoreInfoClick}
            listType="effect"
          />
          <p className="prices-outro-text">
            Bízza ránk a költözés terheit, és kérjen tőlünk árajánlatot még ma!
            Áraink rugalmasak, és minden esetben az Ön egyedi igényeihez
            igazítjuk őket.
          </p>
        </div>

        <CTAPor />
      </div>
    </>
  );
};

export default Prices;
