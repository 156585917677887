import React from "react";
import ListCard from "../ListCard/ListCard";
import "./ListContainer.scss";

const ListContainer = ({
  title,
  data,
  openCardId,
  handleMoreInfoClick,
  listType,
}) => {
  return (
    <div className="list-container-content">
      <h2 className="list-container-content-title">{title}</h2>
      <ul>
        {data.map((item) => (
          <ListCard
            key={item.id}
            listTitle={title}
            data={item}
            openCardId={openCardId}
            handleMoreInfoClick={handleMoreInfoClick}
            listType={listType}
          />
        ))}
      </ul>
    </div>
  );
};

export default ListContainer;
