import "./CTAPor.scss";
import { motion } from "framer-motion";
import { CONTACT } from "../../../contactInfo";
import rightArrow from "../../../assets/icons/ctapor/right.png";

const CTAPor = () => {
  return (
    <div className="cta-por-container">
      <div className="cta-por-wrapper">
        <div>
          <h1 className="cta-por-header">
            Kérjen ajánlatot most, és mi segítünk a költözésben!
          </h1>
          <p className="cta-por-text">
            Minden ajánlatkérés ingyenes és kötelezettségmentes!
          </p>
        </div>

        <div className="cta-por-button-wrapper">
          <motion.a
            className="btn--primary cta-por-button"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            href="/ajanlat"
          >
            Ajánlatkérés
          </motion.a>
        </div>
      </div>
      <div className="cta-por-contact-wrapper">
        <div className="cta-por-contact-box cta-por-email-wrapper">
          <div className="cta-por-email-text">
            <img
              src="https://img.icons8.com/ios/50/000000/email.png"
              alt="email"
            />
            <a href={`mailto:${CONTACT.EMAIL}`}>Írok emailt</a>
          </div>
          <img src={rightArrow} alt="jobbranyíl" className="rarrow" />
        </div>
        <div className="cta-por-contact-box cta-por-phone-wrapper">
          <div className="cta-por-phone-text">
            <img
              src="https://img.icons8.com/ios/50/000000/phone.png"
              alt="phone"
            />
            <a href={`tel:${CONTACT.PHONELINK}`}>Telefonálok</a>
          </div>
          <img src={rightArrow} alt="jobbranyíl" className="rarrow" />
        </div>
      </div>
    </div>
  );
};

export default CTAPor;
