import React from "react";
import { motion } from "framer-motion";
import "./HomeServices.scss";
import homeIcon from "../../../assets/icons/home/homeservices/home.png";
import companyIcon from "../../../assets/icons/home/homeservices/company.png";
import garbageDisposalIcon from "../../../assets/icons/home/homeservices/recycling-bin.png";

const homeServicesData = [
  {
    id: 1,
    title: "Lakossági költöztetés",
    text: "Boldogan segítünk a költözésben, legyen szó lakásról, házról vagy akár albérletről, kicsiről vagy nagyról!",
    img: homeIcon,
    link: "/szolgaltatasok/lakossagi",
  },
  {
    id: 2,
    title: "Céges költöztetés",
    text: "Cége költöztetését is vállaljuk, legyen szó irodáról, raktárról vagy akár teljes telephelyről!",
    img: companyIcon,
    link: "/szolgaltatasok/uzleti",
  },
  {
    id: 3,
    title: "Lomtalanítás",
    text: "Segítünk megszabadulni a felesleges holmiktól, bútoroktól, vagy akár teljes lakás kiürítését is vállaljuk!",
    img: garbageDisposalIcon,
    link: "/szolgaltatasok/lomtalanitas",
  },
];

const HomeServices = () => {
  return (
    <div
      className="container home-services-container"
      id="home-services-container"
    >
      <h2 className="section__title">Szolgáltatásaink</h2>
      <div className="home-services-grid">
        {homeServicesData.map((service) => (
          <motion.div key={service.id} className="home-services-item" 
          whileHover={{ scale: 1.05 }}
          transition={{ type: 'spring', stiffness: 300 }}  >
            <img
              src={service.img}
              alt="szolgáltatás ikon"
              className="home-services-img"
            />
            <h3 className="home-services-title">{service.title}</h3>
            <p className="home-services-text">{service.text}</p>
            <a href={service.link} className="home-services-link">
              Tovább
            </a>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default HomeServices;
