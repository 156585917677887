import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./Navigationbar.scss";
import { motion } from "framer-motion";
import logo from "../../assets/images/logo/koltozzolcson_logo_icon_only_nobuffer_trans_white.png";

const Navigationbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const HamburgerIcon = (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 12h18M3 6h18M3 18h18" stroke="#000" strokeWidth="2" />
    </svg>
  );

  const CloseIcon = (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 18L18 6M6 6l12 12"
        stroke="#000"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );

  const toggleIcon = isOpen ? CloseIcon : HamburgerIcon;

  return (
    <Navbar
      collapseOnSelect
      expand="xl"
      className={`navbar-container ${scrolled ? "scrolled" : ""}`}
      bg="myBlue"
      fixed="top"
      expanded={isOpen}
      onToggle={(expanded) => setIsOpen(expanded)}
    >
      <Navbar.Brand href="/" className="navbar-brand">
        <img
          alt=""
          src={logo}
          width="40"
          height="40"
          className="d-inline-block align-top"
        />Költözz Olcsón
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        className={`navbar-toggle ${isOpen ? "open" : ""}`}
      >
        {toggleIcon}
      </Navbar.Toggle>
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ms-auto">
          <Nav.Link href="/rolunk" className="navbar-link">
            Rólunk
          </Nav.Link>
          <NavDropdown
            title={
              <span className="navbar-dropdown-text">Szolgáltatásaink</span>
            }
          >
            <NavDropdown.Item href="/szolgaltatasok/lakossagi">
              Lakossági költöztetés
            </NavDropdown.Item>
            <NavDropdown.Item href="/szolgaltatasok/uzleti">
              Céges költöztetés
            </NavDropdown.Item>
            <NavDropdown.Item href="/szolgaltatasok/lomtalanitas">
              Lomtalanítás
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="/referenciak" className="navbar-link">
            Referenciák
          </Nav.Link>
          <Nav.Link href="/arak" className="navbar-link">
            Árak
          </Nav.Link>
          <Nav.Link href="/kapcsolat" className="navbar-link">
            Kapcsolat
          </Nav.Link>
          <Nav>
            <motion.a
              href="/ajanlat"
              className="navbar-link navbar-call-btn"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <Nav.Link as="span">Ajánlatkérés</Nav.Link>
            </motion.a>
          </Nav>
          {/* <Nav.Link href={`tel:${CONTACT.PHONELINK}`}className="navbar-link navbar-call-btn">
            Telefonálok
            <span>
              <Lottie
                animationData={animationData}
                className="navbar-lottie-animation"
              />
            </span>
          </Nav.Link> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigationbar;
