import { useState } from "react";
import "./PriceOfferReq.scss";
import { Form, FloatingLabel } from "react-bootstrap";
import { motion } from "framer-motion";
import PageTitle from "../Shared/PageTitle/PageTitle";
import { toast } from "react-toastify";
import Lottie from "lottie-react";
import animationData from "../../assets/animations/formsubmit-success.json";

const PriceOfferReq = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [addressFrom, setAddressFrom] = useState("");
  const [addressTo, setAddressTo] = useState("");
  const [date, setDate] = useState("");
  const [type, setType] = useState("");
  const [bigItems, setBigItems] = useState("");
  const [message, setMessage] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  // const today = new Date().toISOString().split("T")[0];
  const [honeyPot, setHoneyPot] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isFormValid = () => {
    return (
      name &&
      email &&
      phone &&
      addressFrom &&
      addressTo &&
      date &&
      type &&
      bigItems &&
      message &&
      isChecked
    );
  };

  const handleStopAnimation = () => {
    setIsSubmitting(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      toast.error(
        "Kérem töltse ki az összes mezőt és fogadja el az adatvédelmi tájékoztatót!"
      );
      return;
    }

    const data = {
      name,
      email,
      phone,
      addressFrom,
      addressTo,
      date,
      type,
      bigItems,
      message,
      isChecked,
      honeyPot,
    };

    setIsSubmitting(true);
    try {
      const response = await fetch(
        "https://formsubmit.co/ajax/5446ded7ccaf645c259ad8e194996e50",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(data),
          mode: "cors",
        }
      );

      const result = await response.json();

      if (response.ok) {
        toast.success("Az űrlap sikeresen elküldve!");
      } else {
        console.error("Server error:", result);
        // toast.error("Hiba történt az űrlap elküldése közben.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // toast.error(
      //   "Hiba történt az űrlap elküldése közben. Kérjük próbálja újra később."
      // );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle content="Árajánlatkérés" backgroundColor="#f5f5f5" />
      <div className="container price-offer-req-container">
        <div className="por-intro">
          <p>
            Szeretne Ön is problémamentes költözést, rejtett költségek nélkül?
            Kérjen ajánlatot most, és mi segítünk a költözésben!
          </p>
          <p>
            Töltse ki az alábbi űrlapot, és hamarosan felvesszük Önnel a
            kapcsolatot!
          </p>
          <p>
            <strong>Minden ajánlatkérés ingyes és kötelezettségmentes!</strong>
          </p>
        </div>

        <div className="por-form-wrapper">
          <Form className="por-form" name="por-form" onSubmit={handleSubmit}>
            <input
              type="hidden"
              name="honeypot"
              value={honeyPot}
              onChange={(e) => setHoneyPot(e.target.value)}
            />

            <input
              type="hidden"
              name="_blacklist"
              value="spammy pattern, banned term, phrase"
            />
            <input type="hidden" name="_template" value="table" />
            <input type="hidden" name="_subject" value="Új árajánlatkérés!" />
            {/* -- NAME -- */}
            <FloatingLabel
              controlId="floatingName"
              label="Név"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Név"
                name="name"
                required
                autoComplete="on"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FloatingLabel>
            {/* -- EMAIL --  */}
            <FloatingLabel
              controlId="floatingEmail"
              label="Email"
              className="mb-3"
            >
              <Form.Control
                type="email"
                placeholder="Email"
                name="email"
                autoComplete="on"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FloatingLabel>
            {/* -- PHONE -- */}
            <FloatingLabel
              controlId="floatingPhone"
              label="Telefonszám"
              className="mb-3"
            >
              <Form.Control
                type="tel"
                placeholder="Telefonszám"
                name="phone"
                required
                autoComplete="on"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </FloatingLabel>
            {/* -- ADDRESS FROM -- */}
            <FloatingLabel
              controlId="floatingAddressFrom"
              label="Indulási cím"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Indulási cím"
                name="addressFrom"
                required
                value={addressFrom}
                onChange={(e) => setAddressFrom(e.target.value)}
              />
            </FloatingLabel>
            {/* -- ADDRESS TO -- */}
            <FloatingLabel
              controlId="floatingAddressTo"
              label="Érkezési cím"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Érkezési cím"
                name="addressTo"
                required
                value={addressTo}
                onChange={(e) => setAddressTo(e.target.value)}
              />
            </FloatingLabel>
            {/* -- DATE -- */}
            <FloatingLabel
              controlId="floatingDate"
              label="Költözés dátuma"
              className="mb-3"
            >
              <Form.Control
                type="date"
                placeholder="Költözés dátuma"
                name="date"
                required
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </FloatingLabel>
            {/* -- TYPE -- */}
            <FloatingLabel
              controlId="floatingSelectGrid"
              label="Költöztetendő ingatlan mérete"
              className="mb-3"
            >
              <Form.Select
                aria-label="Költöztetési típus választása"
                name="type"
                value={type}
                required
                onChange={(e) => setType(e.target.value)}
              >
                <option>Válasszon egyet a lehetőségek közül</option>
                <option value="Kicsi">35 négyzetméter alatt</option>
                <option value="Közepes">35 és 65 négyzetméter között</option>
                <option value="Nagy">65 és 100 négyzetméter között</option>
                <option value="Speciális">100 négyzetméter felett</option>
              </Form.Select>
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingSelectGridForBigItems"
              label="Nehéz tárgyak száma"
              className="mb-3"
            >
              <Form.Select
                aria-label="Nehéz tárgyak száma"
                name="bigItems"
                value={bigItems}
                required
                onChange={(e) => setBigItems(e.target.value)}
              >
                <option>Válasszon egyet a lehetőségek közül</option>
                <option value="Nincs nehéz tárgy">Nincs nehéz tárgy</option>
                <option value="1db">1 db</option>
                <option value="2db">2 db </option>
                <option value="3db">3 db </option>
                <option value="4db">4 db vagy annál több</option>
                {/* -- MESSAGE -- */}
              </Form.Select>
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingMessage"
              label="További információk (pl. emelet, lift, stb.)"
              className="mb-3"
            >
              <Form.Control
                as="textarea"
                name="message"
                style={{ height: "100px" }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </FloatingLabel>
            <div className="por-form-checkbox-wrapper">
              <input
                type="checkbox"
                name="checkbox"
                className="por-form-checkbox"
                required
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              ></input>
              <span className="por-form-checkbox-text">
                Elolvastam, megértettem és elfogadom az{" "}
                <a
                  href="/assets/koltozzolcson_adatkezelesi_tajekoztato_240827.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  adatvédelmi tájékoztatót
                </a>
                .
              </span>
            </div>
            {!isSubmitting ? (
              <>
                <div className="cta-por-button-wrapper">
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    type="submit"
                    className="btn btn--primary"
                    disabled={!isFormValid()}
                    onClick={handleSubmit}
                  >
                    Elküldöm
                  </motion.button>
                </div>
                <p className="por-form-info">
                  * Az űrlap beküldése csak akkor lehetséges, ha minden mező ki
                  van töltve, illetve az adatvédelmi tájékoztatót elolvasta és
                  elfogadta.
                </p>
              </>
            ) : (
              <div className="por-form-submit-animation_wrapper">
                <Lottie
                  animationData={animationData}
                  style={{ width: 200, height: 200 }}
                  className="por-form-submit-animation"
                />
                <div className="por-form-submit-info-wrapper">
                  <p className="por-form-info">Az űrlap sikeresen elküldve! </p>
                  <p className="por-form-info">
                    Hamarosan felvesszük Önnel a kapcsolatot.
                  </p>
                  <button className="btn" onClick={handleStopAnimation}>
                    Ok!
                  </button>
                </div>
              </div>
            )}
          </Form>
        </div>
      </div>
    </>
  );
};

export default PriceOfferReq;
