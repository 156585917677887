import React from "react";
import "../../../styles/main.scss";
import "./ListCard.scss";

// const ListCard = ({ data, openCardId, handleMoreInfoClick, listType }) => {
//   return (
//     <li key={data.id} className="list-card">
//       <img src={data.img} alt={data.imgAlt} />
//       <h3>{data.title}</h3>
//       <button
//         className="list-card-btn"
//         onClick={() => handleMoreInfoClick(listType, data.id)}
//       >
//         Részletek
//       </button>
//       {openCardId === `${listType}-${data.id}` && (
//         <div className="list-extra-info visible">
//           <p>{data.pricesExtendedText}</p>
//         </div>
//       )}
//     </li>
//   );
// };

// export default ListCard;

const ListCard = ({ data, openCardId, handleMoreInfoClick, listType }) => {
  return (
    <li key={data.id} className="list-card">
      {data.img && data.img.length > 0 ? (
        <img src={data.img} alt={data.imgAlt} />
      ) : null}
      <h3>{data.title}</h3>
      <button
        className="list-card-btn"
        onClick={() => handleMoreInfoClick(listType, data.id)}
      >
        Részletek
      </button>
      {openCardId === `${listType}-${data.id}` && (
        <div className="list-extra-info visible">
          <p>{data.pricesExtendedText || data.content}</p>
        </div>
      )}
    </li>
  );
};

export default ListCard;
