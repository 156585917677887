import React, { useState } from "react";
import "./Services.scss";
import "../../components/Prices/Prices.scss";
import { Accordion } from "react-bootstrap";
import PageTitle from "../Shared/PageTitle/PageTitle";
import {
  servicesPersonalServiceContainsData,
  servicesPersonalAccordionData,
  servicesPersonalMovingTipsData,
} from "./servicesData";
import CTAPor from "../Shared/CTAPor/CTAPor";
import ListContainer from "../Shared/ListContainer/ListContainer";

const ServicesPersonal = () => {
  const [openCardId, setOpenCardId] = useState(null);

  const handleMoreInfoClick = (listType, cardId) => {
    const uniqueId = `${listType}-${cardId}`;
    setOpenCardId((prevId) => (prevId === uniqueId ? null : uniqueId));
  };

  return (
    <>
      <PageTitle content="Lakossági költöztetés" backgroundColor="#f4ece0"/>
      <div className="container services-container services-personal-container">
        <div className="services-wrapper services-personal-wrapper">
          <div className="services-intro services-personal-intro">
            <p className="services-intro-text">
              A lakossági költöztetés során kiemelt figyelmet fordítunk arra,
              hogy otthona biztonságban és sértetlenül kerüljön át az új
              helyszínre. Tudjuk, hogy a költözés stresszes és időigényes lehet,
              ezért mindenben segítünk, hogy ez az élmény könnyű és gördülékeny
              legyen.
            </p>
          </div>
          <ListContainer
            title="Miért minket válasszon?"
            data={servicesPersonalServiceContainsData}
            openCardId={openCardId}
            handleMoreInfoClick={handleMoreInfoClick}
            listType="services"
          />

          <CTAPor />

          <div className="faq-container">
            <h2 className="faq-header">Gyakran ismételt kérdések</h2>
            <div className="faq-accordion-container">
              <Accordion defaultActiveKey={["0"]} alwaysOpen>
                {servicesPersonalAccordionData.map((item) => (
                  <Accordion.Item eventKey={item.id.toString()} key={item.id}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body>{item.content}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>

          <div className="services-tips">
            <h2 className="services-tips-title">
              Tippek és javaslatok a zökkenőmentes költözéshez
            </h2>
            <p className="services-tips-intro">
              A költözés stresszes és időigényes feladat lehet, de néhány jól
              megválasztott lépéssel sokkal könnyebbé teheti a folyamatot.
              Összegyűjtöttünk néhány hasznos tippet, amelyek segíthetnek abban,
              hogy a költözés gördülékeny és problémamentes legyen.
            </p>
            <div className="tip-item">
              {servicesPersonalMovingTipsData.map((tip) => (
                <div key={tip.id} className="tip-item">
                  <h2>{tip.itemTitle}</h2>
                  <p>{tip.itemIntro}</p>
                  {tip.itemList && (
                    <ul>
                      {tip.itemList.map((item, index) => (
                        <li key={index}>
                          <h3>{item.itemListTitle}</h3>
                          <p>{item.itemListContent}</p>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesPersonal;
