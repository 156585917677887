import "./Contact.scss";
import { CONTACT } from "../../contactInfo";
import PageTitle from "../Shared/PageTitle/PageTitle";
import CTAPor from "../Shared/CTAPor/CTAPor";
import locationIcon from "../../assets/icons/contact/pin.png";
import phoneIcon from "../../assets/icons/contact/call.png";
import emailIcon from "../../assets/icons/contact/email-2.png";
import officeIcon from "../../assets/icons/contact/company.png";
import googleTerkep from "../../assets/images/contact/googleterkep.webp";
import FreqAskQuestions from "./FreqAskQuestions/FreqAskQuestions";

const Contact = () => {
  return (
    <>
      <PageTitle content="Kapcsolat" backgroundColor="#f0f5ff"/>
      <div className="container contact-container">
        <div className="contact-us-info-wrapper">
          <div className="contact-us-info-intro-text">
            <p>
              Szeretne többet megtudni rólunk, vagy kérdése van a
              szolgáltatásainkkal kapcsolatban?
            </p>
            <p>
              Vegye fel velünk a kapcsolatot az alábbi elérhetőségeinken!
            </p>
          </div>
          <CTAPor />
          <div className="contact-us-info-grid">
            <div className="contact-us-info-box-address">
              <p className="contact-us-info-box-title">Elérhetőségeink</p>
              <div className="contact-us-info-box-text-item ">
                <img
                  src={locationIcon}
                  alt="cím ikon"
                  className="contact-us-info-box-text-icon"
                />
                <p className="contact-us-info-box-text">
                  {CONTACT.ADDRESS}
                </p>
              </div>
              <div className="contact-us-info-box-text-item ">
                <img src={phoneIcon} alt="telefon ikon" />
                <p className="contact-us-info-box-text">{CONTACT.PHONE}</p>
              </div>
              <div className="contact-us-info-box-text-item">
                <img src={emailIcon} alt="email ikon" />
                <p className="contact-us-info-box-text">{CONTACT.EMAIL}</p>
              </div>
            </div>
            <div className="contact-us-info-box-openhours">
              <p className="contact-us-info-box-title">Nyitvatartás</p>
              <div className="contact-us-info-box-text-item">
                <img src={officeIcon} alt="iroda ikon" />
                <p className="contact-us-info-box-text-title">Iroda</p>
                <div>
                  <p className="contact-us-info-box-text">
                    0-24 órában elérhetőek vagyunk telefonon és emailben is.
                  </p>
                </div>
              </div>
            </div>
            <div className="contact-us-info-box-map">
              <div className="contact-us-info-box-map-img">
                <a
                  href="https://maps.app.goo.gl/C4AoETG8MgLsHMcf6"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={googleTerkep}
                    alt="telephely térkép linkkel"
                    rel="preload"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <FreqAskQuestions />
      </div>
    </>
  );
};

export default Contact;
