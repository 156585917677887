import "./HomeTestimonials.scss";
import { motion } from "framer-motion";
import quoteIcon from "../../../assets/icons/home/hometestimonials/quote.png";
const homeTestimonialsData = [
  {
    id: 1,
    name: "Kiss József",
    text: "Nagyon elégedett vagyok a szolgáltatásukkal, gyorsak és precízek voltak!",
    img: "https://img.logoipsum.com/331.svg",
  },
  {
    id: 2,
    name: "Németh András",
    text: "Dominik és csapata nagyon profi módon végezte el a költöztetést, csak ajánlani tudom őket!",
    img: "https://img.logoipsum.com/331.svg",
  },
  {
    id: 3,
    name: "Bognár Dávid",
    text: "Irodánk költöztetését bíztuk rájuk, és nem csalódtunk! Gyorsak, precízek és kedvesek voltak!",
    img: "https://img.logoipsum.com/331.svg",
  },
];

const HomeTestimonials = () => {
  return (
    <div className="container home-testimonials-container"
    id="home-testimonials-container"
    >
      <h2 className="section__title">
        Mit mondanak rólunk elégedett ügyfeleink?
      </h2>
      <div className="home-testimonials-grid">
        {homeTestimonialsData.map((testimonial) => (
          <motion.div key={testimonial.id} className="home-testimonial-item"           initial={{ opacity: 0, x: -50 }} // Adjusted initial position
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
          onAnimationStart={() => console.log('Animation started')}
          onAnimationComplete={() => console.log('Animation completed')} >
            <img
              src={quoteIcon}
              alt="idézőjel ikon a véleményekhez"
              className="home-testimonial-img"
            />
            <p className="home-testimonial-text">{testimonial.text}</p>
            <p className="home-testimonial-author">{testimonial.name}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default HomeTestimonials;
