import { motion } from "framer-motion";
import "./NotImplementedPage.scss";

const NotImplementedPage = () => {
  return (
    <>
      <motion.div
        className="not-implemented-page-container container"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="not-implemented-page__title">
          Ez az oldal jelenleg feltöltés alatt áll!
        </h1>
        <p className="not-implemented-page__text">
          Kérjük nézzen vissza később!
        </p>
      </motion.div>
    </>
  );
};

export default NotImplementedPage;
