import React from "react";
import { Routes, Route } from "react-router-dom";

import Navigationbar from "./components/Navigationbar/Navigationbar";
import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";

import "./styles/main.scss";
import Prices from "./components/Prices/Prices";
import PriceOfferReq from "./components/PriceOfferReq/PriceOfferReq";
import Contact from "./components/Contact/Contact";
import About from "./components/About/About";
import ServicesPersonal from "./components/Services/ServicesPersonal";
import ServicesCompany from "./components/Services/ServicesCompany";
import ServicesGarbage from "./components/Services/ServicesGarbage";
import NotImplementedPage from "./components/NotImplementedPage/NotImplementedPage";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Impressum from "./components/Impressum/Impressum";

function App() {
  return (
    <>
      <Navigationbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/arak" element={<Prices />} />
        <Route path="/ajanlat" element={<PriceOfferReq />} />
        <Route path="/rolunk" element={<About />} />
        <Route
          path="/szolgaltatasok/lakossagi"
          element={<ServicesPersonal />}
        />
        <Route path="/szolgaltatasok/uzleti" element={<ServicesCompany />} />
        <Route
          path="/szolgaltatasok/lomtalanitas"
          element={<ServicesGarbage />}
        />
        <Route path="/kapcsolat" element={<Contact />} />
        <Route path="/referenciak" element={<NotImplementedPage />} />
        <Route path="/blog" element={<NotImplementedPage />} />
        <Route path="/impresszum" element={<Impressum />} />
      </Routes>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default App;
