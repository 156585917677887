import "./Home.scss";
import HomeHero from "./HomeHero/HomeHero";
import HomeReferencies from "./HomeReferencies/HomeReferencies";
import HomeServices from "./HomeServices/HomeServices";
import HomeTestimonials from "./HomeTestimonials/HomeTestimonials";

const Home = () => {
  return (
    <>
      <HomeHero />
      <HomeServices />
      <HomeReferencies />
      <HomeTestimonials />
    </>
  );
};

export default Home;
