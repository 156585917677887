export const servicesPersonalServiceContainsData = [
  // {
  //   id: 1,
  //   title: "Előzetes helyszíni felmérés",
  //   content: `Szolgáltatásunk részeként előzetes helyszíni felmérést végzünk, hogy pontosan felmérjük az ingóságok mennyiségét és a költöztetés körülményeit. Ez segít abban, hogy megfelelően felkészüljünk és hatékonyan végezzük el a munkát.`,
  // },
  {
    id: 1,
    title: "Árajánlat készítése",
    content: `A beérkezett árajánlatkérő űrlap alapján részletes árajánlatot készítünk, amely tartalmazza a költöztetés minden költségét. Így előre tudni fogja, milyen kiadásokra számíthat.
    Amennyiben a költöztetés napján a helyszínen derül ki, hogy a költöztetéshez szükséges idő és munkaerő több vagy kevesebb, mint amennyit az árajánlat tartalmaz, úgy a költöztetés ára ennek megfelelően módosulhat.
    A költöztetés mértékétől függően egyeztethető a helyszíni felmérés is.`,
  },
  {
    id: 2,
    title: "Ingóságok csomagolása",
    content: `Igény esetén a becsomagolás is az árajánlatunk része lehet, ekkor szakszerűen becsomagoljuk az összes ingóságot, hogy azok biztonságosan eljussanak az új helyükre. Különös figyelmet fordítunk a törékeny tárgyakra és értékes holmikra.`,
  },
  {
    id: 3,
    title: "Bútorok szétszerelése és összeszerelése",
    content: `Szolgáltatásunk részeként szétszereljük a nagyobb bútorokat a költözés előtt, és az új helyen is összeszereljük őket. Így Önnek nem kell ezzel foglalkoznia, és gyorsabban befejezheti a költözést.`,
  },
  {
    id: 4,
    title: "Ingóságok szállítása",
    content: `Biztonságosan és hatékonyan szállítjuk az összes ingóságot az új helyszínre. Modern járműparkunk és tapasztalt sofőrjeink garantálják a gyors és biztonságos szállítást.`,
  },
  {
    id: 5,
    title: "Ingóságok kipakolása",
    content: `Az új helyszínen segítünk kipakolni az ingóságokat, és a kívánt helyükre tesszük őket. Így Önnek csak annyi dolga marad, hogy élvezze az új otthonát.`,
  },
  {
    id: 6,
    title: "Elégedettségi garancia",
    content: `Szolgáltatásunkra garanciát vállalunk, és elégedettségi garanciát kínálunk minden ügyfelünknek. Ha nem elégedett a szolgáltatással, kérje vissza a díjat!`,
  },
];

export const servicesPersonalAccordionData = [
  {
    id: 1,
    title: "Hogyan készítsem elő a költözést?",
    content: `Fontos, hogy készítsen egy listát a költöztetendő tárgyakról, és külön jelölje az értékes vagy törékeny dolgokat. Ha szeretne, segítünk a csomagolásban is, így Önnek nem kell ezzel foglalkoznia.`,
  },
  {
    id: 2,
    title: "Mikor érdemes költöztetőt hívni?",
    content: `Abban az esetben érdemes elgondolkodnia egy költöztető megbízásán, ha nincs elég idő vagy kapacitás a költözésre, vagy ha nagyobb tárgyakat kell szállítani. Egy költöztető segítségével gyorsan és hatékonyan költözhet új otthonába. Természetesen minden méretben vállalunk költöztetést, legyen szó egy kisebb méretű szobáról, vagy akár egy nagyméretű raktárról.`,
  },
  {
    id: 3,
    title: "Mennyi időt vesz igénybe egy lakossági költöztetés?",
    content: `A költözés időtartama nagyban függ a távolságtól és a szállítandó tárgyak mennyiségétől. Általában egy átlagos méretű lakás költöztetése néhány órától egy napig is tarthat.`,
  },
  {
    id: 4,
    title: "Milyen költségvetéssel számoljak?",
    content: `A költöztetés költsége nagyban függ a szállítandó tárgyak mennyiségétől és a távolságtól. Az árakat befolyásolhatja az is, ha extra szolgáltatásokat szeretne igénybe venni, mint például a csomagolás vagy a bútorszerelés, de akár a parkolás is.`,
  },
  {
    id: 5,
    title: "Hogyan biztosítják a tárgyak épségét?",
    content: `Minden tárgyat gondosan becsomagolunk, szükség esetén védőanyagokkal látunk el. Tapasztalt munkatársaink óvatosan mozgatják és rögzítik a bútorokat, hogy azok biztonságban érjenek célba.`,
  },

  {
    id: 6,
    title: "Nagyértékű tárgyaimat hogyan szállítják?",
    content: `Nagy értékű tárgyakat különös figyelemmel kezelünk, és extra óvatossággal szállítjuk. Szolgáltatásunkhoz jár alapvető biztosítás, de amennyiben szükséges, extra biztosítást is lehet kérni.`,
  },
];

export const servicesPersonalMovingTipsData = [
  {
    id: 1,
    itemTitle: "Kezdje időben a tervezést!",
    itemIntro:
      "A költözés tervezése kulcsfontosságú. Érdemes legalább 4-6 héttel a költözés előtt elkezdeni az előkészületeket. Íme, mire érdemes figyelni:",
    itemList: [
      {
        itemListTitle: "Készítsen költözési listát:",
        itemListContent:
          "Jegyezze fel a teendőket, és készítsen egy ütemtervet. Jelölje meg a fontos határidőket, mint például a csomagolás elkezdése, a régi szolgáltatások lemondása, illetve az új szolgáltatások bekötése.",
      },
      {
        itemListTitle: "Szelektálás:",
        itemListContent:
          "Szabaduljon meg azoktól a tárgyaktól, amikre már nincs szüksége. Ezzel csökkentheti a költöztetendő holmik mennyiségét, és könnyebbé válik a pakolás.",
      },
    ],
  },
  {
    id: 2,
    itemTitle: "Hatékony csomagolás",
    itemIntro:
      "A csomagolás az egyik legfontosabb lépés a költözés során, és sok időt spórolhat meg, ha okosan csomagol.",
    itemList: [
      {
        itemListTitle: "Címkézzen mindent:",
        itemListContent:
          "Jelölje meg minden dobozt, hogy melyik szobába kerül majd az új helyen, és írja rá, mi van benne. Ez megkönnyíti a kicsomagolást, és a költöztetőknek is segít gyorsabban megtalálni a dolgok helyét.",
      },
      {
        itemListTitle: "Pakoljon szobánként:",
        itemListContent:
          "Minden szoba tárgyait külön dobozokba csomagolja, hogy az új helyen egyszerűbb legyen kicsomagolni.",
      },
      {
        itemListTitle: "Ne hagyjon üres helyet a dobozokban:",
        itemListContent:
          "A dobozokban lévő tárgyak mozoghatnak szállítás közben, ami sérüléseket okozhat. Töltse ki a dobozok üres részeit ruhákkal, papírokkal vagy törölközőkkel.",
      },
      {
        itemListTitle: "Fontos dolgok külön csomagolása:",
        itemListContent:
          "Csomagolja el külön azokat a dolgokat, amelyekre azonnal szüksége lesz az új otthonban, például személyes iratokat, gyógyszereket, tisztálkodási szereket.",
      },
    ],
  },
  {
    id: 3,
    itemTitle: "Készítse elő a nagyobb bútorokat és eszközöket",
    itemIntro:
      "A nagyobb bútorok, mint az ágyak, szekrények vagy a háztartási gépek külön figyelmet igényelnek.",
    itemList: [
      {
        itemListTitle: "Szereljen le mindent, amit lehet:",
        itemListContent:
          "A nagy bútorokat érdemes szétszerelni, hogy könnyebben lehessen mozgatni és szállítani. Ne felejtse el gondosan becsomagolni a csavarokat és egyéb alkatrészeket!",
      },
      {
        itemListTitle: "Tisztítsa meg a háztartási gépeket:",
        itemListContent:
          "A költözés előtt tisztítsa ki a hűtőt, mosógépet és egyéb háztartási eszközöket, és engedje le a vizet, ha szükséges. A tiszta és üres gépek könnyebben kezelhetők és biztonságosabban szállíthatók.",
      },
    ],
  },
  {
    id: 4,
    itemTitle: "Készítsen túlélőcsomagot az első napokra",
    itemIntro:
      'Az első nap az új otthonban általában káosz lehet, ezért érdemes egy "túlélőcsomagot" összeállítani. Ebben legyenek olyan alapvető dolgok, amelyeket azonnal elérhet, mint például:',
    itemList: [
      {
        itemListTitle:
          "Ágynemű, párnák, törölközők és alapvető tisztálkodási szerek, konyhai alapfelszerelés (bögre, tányér, evőeszköz, vízforraló) és alapvető gyógyszerek és egészségügyi eszközök",
      },
    ],
  },
  {
    id: 5,
    itemTitle: "Gondoskodjon a gyerekekről és háziállatokról",
    itemIntro:
      "Ha gyerekekkel vagy háziállatokkal költözik, külön figyelmet igényelnek.",
    itemList: [
      {
        itemListTitle: "Gyerekek:",
        itemListContent:
          "Tervezze meg előre, hogyan fogja lekötni a gyerekeket a költözés napján. Egy külön csomag játékokkal vagy elektronikus eszközökkel sokat segíthet.",
      },
      {
        itemListTitle: "Háziállatok:",
        itemListContent:
          "A háziállatok stresszesen reagálhatnak a költözésre, ezért érdemes őket először egy nyugodt helyen tartani az új otthonban, amíg a költöztetés lezajlik.",
      },
    ],
  },
  {
    id: 6,
    itemTitle: "Intézze el a hivatalos teendőket",
    itemIntro: "Ne feledkezzen meg az alábbi hivatalos lépésekről:",
    itemList: [
      {
        itemListTitle: "Lakcímváltozás bejelentése:",
        itemListContent:
          "Minden fontos szolgáltatónál (bank, közművek, postai szolgáltatások) intézze el a lakcímváltozás bejelentését.",
      },
      {
        itemListTitle: "Közművek le- és bekapcsolása:",
        itemListContent:
          "Gondoskodjon arról, hogy az új otthonban minden közmű (víz, villany, gáz, internet) időben bekötésre kerüljön, és a régieket lemondja.",
      },
    ],
  },
  {
    id: 7,
    itemTitle: "Legyen rugalmas!",
    itemIntro:
      'Bár a költözés előkészítése fontos, legyen kész arra, hogy az utolsó pillanatban is változhatnak a dolgok. Legyen egy "B" terve, és ne stresszeljen, ha valami nem azonnal a tervek szerint alakul.',
  },
];

export const servicesBusinessServiceContainsData = [
  {
    id: 1,
    title: "Előzetes helyszíni felmérés",
    content: `Szolgáltatásunk részeként igény esetén előzetes helyszíni felmérést végzünk, hogy pontosan felmérjük az irodai ingóságok mennyiségét és a költöztetés körülményeit. Ez segít abban, hogy megfelelően felkészüljünk és hatékonyan végezzük el a munkát.`,
  },
  {
    id: 2,
    title: "Árajánlat készítése",
    content: `A beérkezett árajánlatkérő űrlap vagy helyszíni felmérés alapján részletes árajánlatot készítünk, amely tartalmazza az irodai költöztetés minden költségét. Így előre tudni fogja, milyen kiadásokra számíthat. Amennyiben a költöztetés napján a helyszínen derül ki, hogy a költöztetéshez szükséges idő és munkaerő több vagy kevesebb, mint amennyit az árajánlat tartalmaz, úgy a költöztetés ára ennek megfelelően módosulhat.`,
  },
  {
    id: 3,
    title: "Irodai bútorok szétszerelése és összeszerelése",
    content: `Szolgáltatásként kérhető, hogy szétszereljük az irodai bútorokat a költözés előtt, és az új helyen is összeszereljük őket. Így Önnek nem kell ezzel foglalkoznia, és gyorsabban befejezheti a költözést.`,
  },
  {
    id: 4,
    title: "Irodai eszközök csomagolása",
    content: `Igény esetén a becsomagolás is az árajánlatunk része lehet, ekkor szakszerűen becsomagoljuk az összes irodai eszközt, hogy azok biztonságosan eljussanak az új helyszínre. Különös figyelmet fordítunk a törékeny tárgyakra és értékes holmikra.`,
  },
  {
    id: 5,
    title: "Irodai eszközök szállítása",
    content: `Biztonságosan és hatékonyan szállítjuk az összes irodai eszközt az új helyszínre. Modern járműparkunk és tapasztalt sofőrjeink garantálják a gyors és biztonságos szállítást.`,
  },
  {
    id: 6,
    title: "Irodai eszközök kipakolása",
    content: `Az új helyszínen segítünk kipakolni az irodai eszközöket, és a kívánt helyükre tesszük őket. Így Önnek csak annyi dolga marad, hogy élvezze az új irodáját.`,
  },
  {
    id: 7,
    title: "Elégedettségi garancia",
    content: `Szolgáltatásunkra garanciát vállalunk, és elégedettségi garanciát kínálunk minden ügyfelünknek. Ha nem elégedett a szolgáltatással, kérje vissza a díjat!`,
  },
];

export const servicesBusinessAccordionData = [
  {
    id: 1,
    title: "Hogyan készítsem elő az irodai költözést?",
    content: `Fontos, hogy készítsen egy listát az irodai eszközökről, és külön jelölje az értékes vagy törékeny dolgokat. Ha szeretne, segítünk a csomagolásban is, így Önnek nem kell ezzel foglalkoznia.`,
  },
  {
    id: 2,
    title: "Mikor érdemes költöztetőt hívni?",
    content: `Abban az esetben érdemes elgondolkodnia egy költöztető megbízásán, ha nincs elég idő vagy kapacitás az irodai költözésre, vagy ha nagyobb tárgyakat kell szállítani. Egy költöztető segítségével gyorsan és hatékonyan költözhet új irodájába. Természetesen minden méretben vállalunk költöztetést, legyen szó egy kisebb irodáról, vagy akár egy nagyméretű irodaházról.`,
  },
  {
    id: 3,
    title: "Mennyi időt vesz igénybe egy irodai költöztetés?",
    content: `Az irodai költözés időtartama nagyban függ az iroda méretétől és a szállítandó eszközök mennyiségétől. Általában egy átlagos méretű iroda költöztetése néhány órától egy napig is tarthat.`,
  },
  {
    id: 4,
    title: "Milyen költségvetéssel számoljak?",
    content: `Az irodai költöztetés költsége nagyban függ az iroda méretétől és a szállítandó eszközök mennyiségétől. Az árakat befolyásolhatja az is, ha extra szolgáltatásokat szeretne igénybe venni, mint például a csomagolás vagy a bútorszerelés, de akár a parkolás is.`,
  },
  {
    id: 5,
    title: "Hogyan csökkenthető a költözés miatti kiesés az üzletmenetből?",
    content: `Rugalmas időbeosztással dolgozunk, akár éjszaka vagy hétvégén is, hogy a költözés ne zavarja meg az irodai munkát. Emellett minden lépést előre megtervezünk, hogy a folyamat a lehető leggyorsabb legyen.`,
  },
  {
    id: 6,
    title: "Miként gondoskodnak az adatbiztonságról a költöztetés során?",
    content: `Az érzékeny adatokat tartalmazó dokumentumokat és eszközöket külön tárolókban, zártan és biztonságosan szállítjuk. Különös figyelmet fordítunk arra, hogy a költöztetés során semmilyen bizalmas információ ne kerüljön illetéktelen kezekbe.`,
  },
];

export const servicesBusinessMovingTipsData = [
  {
    id: 1,
    itemTitle: "Alapos tervezés és előkészítés",
    itemIntro:
      "A céges költözés tervezése hónapokkal a tényleges költözés előtt kezdődik. A kulcs itt a részletek gondos megtervezése.",
    itemList: [
      {
        itemListTitle: "Készítsen egy részletes ütemtervet:",
        itemListContent:
          "Minden lépést előre meg kell tervezni, beleértve a költözés napjának meghatározását, az új iroda berendezését és a technikai infrastruktúra kiépítését is.",
      },
      {
        itemListTitle: "Munkatársak tájékoztatása:",
        itemListContent:
          "Időben értesítse a kollégákat a költözésről, és ossza meg velük a költözés pontos ütemtervét. Ez segít minimalizálni a költözés miatti kiesést az üzletmenetben.",
      },
    ],
  },
  {
    id: 2,
    itemTitle: "Különösen figyeljen az IT-eszközökre",
    itemIntro:
      "Az irodai technológia kiemelt szerepet játszik a céges költözésben.",
    itemList: [
      {
        itemListTitle: "Készítsen biztonsági mentéseket:",
        itemListContent:
          "Mielőtt bármilyen IT-eszközt szétszerelnének, készítsenek biztonsági mentéseket az összes fontos adatállományról.",
      },
      {
        itemListTitle: "IT infrastruktúra tervezés:",
        itemListContent:
          "Győződjön meg róla, hogy az új irodában a hálózat, a szerverek és minden szükséges eszköz üzemkész állapotban van a költözés előtt, hogy ne legyen fennakadás a munkában.",
      },
    ],
  },
  {
    id: 3,
    itemTitle: "Iratok és bizalmas adatok kezelése",
    itemIntro:
      "Az irodai dokumentumok költöztetése különös figyelmet igényel, különösen, ha érzékeny adatokat tartalmaznak.",
    itemList: [
      {
        itemListTitle: "Dokumentumok rendszerezése:",
        itemListContent:
          "Mielőtt bármilyen iratot csomagolna, gondolja át, melyik dokumentumot szükséges megtartani, és mit lehet archiválni vagy megsemmisíteni. Ezzel csökkentheti a költöztetendő iratok mennyiségét.",
      },
      {
        itemListTitle: "Bizalmas anyagok biztonságos szállítása:",
        itemListContent:
          "Az érzékeny információkat tartalmazó iratokat és adathordozókat zárt tárolókban érdemes szállítani, hogy azok biztonságban maradjanak.",
      },
    ],
  },
  {
    id: 4,
    itemTitle: "Zökkenőmentes újrakezdés",
    itemIntro:
      "Az irodai költözés utolsó lépése az új helyszínen történő zökkenőmentes beilleszkedés.",
    itemList: [
      {
        itemListTitle: "Teszteljen mindent:",
        itemListContent:
          "A költözés után azonnal tesztelje az IT rendszereket, a telefonokat és az internetkapcsolatot, hogy a munkatársak rögtön elkezdhessenek dolgozni.",
      },
      {
        itemListTitle: "Ügyfelek értesítése:",
        itemListContent:
          "Értesítse ügyfeleit az új irodai elérhetőségekről és a költözésről, hogy zökkenőmentesen folytathassák az együttműködést.",
      },
    ],
  },
];

export const servicesGarbageCollectionServiceContainsData = [
  // {
  //   id: 1,
  //   title: "Előzetes helyszíni felmérés",
  //   content: `Szolgáltatásunk részeként előzetes helyszíni felmérést végzünk, hogy pontosan felmérjük a szemét mennyiségét és a szállítás körülményeit. Ez segít abban, hogy megfelelően felkészüljünk és hatékonyan végezzük el a munkát.`,
  // },
  {
    id: 1,
    title: "Árajánlat készítése",
    content: `A beérkezett árajánlatkérő űrlap alapján részletes árajánlatot készítünk, amely tartalmazza a szemét elszállításának minden költségét. Így előre tudni fogja, milyen kiadásokra számíthat. Amennyiben a lomtalanítás napján a helyszínen derül ki, hogy a költöztetéshez szükséges idő és munkaerő több vagy kevesebb, mint amennyit az árajánlat tartalmaz, úgy a szolgáltatás ára ennek megfelelően módosulhat.`,
  },
  {
    id: 2,
    title: "Szemét elszállítása",
    content: `Biztonságosan és hatékonyan szállítjuk el a szemetet a megfelelő hulladéklerakóba. Modern járműparkunk és tapasztalt sofőrjeink garantálják a gyors és biztonságos szállítást.`,
  },
  {
    id: 3,
    title: "Környezetbarát megoldások",
    content: `Ügyelünk arra, hogy a hulladék a megfelelő helyre kerüljön, és lehetőség szerint újrahasznosításra kerüljön sor.`,
  },
  {
    id: 4,
    title: "Gyors és rugalmas szolgáltatás",
    content: `Akár hétvégén vagy munkaidőn kívül is állunk rendelkezésére, hogy Önnek minél kevesebb kellemetlenséggel járjon a lomtalanítás.`,
  },
  {
    id: 5,
    title: "Elégedettségi garancia",
    content: `Szolgáltatásunkra garanciát vállalunk, és elégedettségi garanciát kínálunk minden ügyfelünknek. Ha nem elégedett a szolgáltatással, kérje vissza a díjat!`,
  },
];

export const servicesGarbageCollectionAccordionData = [
  {
    id: 1,
    title: "Milyen tárgyakat lehet lomtalanítani?",
    content: `Szinte bármilyen feleslegessé vált tárgyat elszállítunk, legyen szó régi bútorokról, elektronikai eszközökről, háztartási gépekről vagy egyéb nagyobb tárgyakról. Ha nem biztos benne, hogy valami elszállítható-e, kérdezzen bátran!`,
  },
  {
    id: 2,
    title: "Mennyi idő alatt történik meg a lomtalanítás?",
    content: ` A lomtalanítás időtartama nagyban függ a szemét mennyiségétől és a körülményektől. Egy átlagos méretű lakás lomtalanítása általában néhány órát vesz igénybe.`,
  },
  {
    id: 3,
    title: "Mi történik a lomtalanított tárgyakkal?",
    content: `A tárgyakat környezetbarát módon kezeljük. Amit lehet, újrahasznosítunk, a többit pedig a megfelelő hulladékkezelő telepre szállítjuk. Célunk, hogy a lehető legkevesebb hulladék kerüljön szemétlerakóba.`,
  },
];

export const servicesGarbageCollectionMovingTipsData = [
  {
    id: 1,
    itemTitle: "Szortírozza és rendszerezze a tárgyakat",
    itemIntro:
      "A lomtalanítás első lépése a felesleges dolgok kiválogatása és rendszerezése.",
    itemList: [
      {
        itemListTitle: "Kategorizálás:",
        itemListContent:
          "Ossza fel a tárgyakat különböző kategóriákba: újrahasznosítható, adományozható, eladható, kidobandó. Ezzel egyszerűbb lesz eldönteni, mit hova küldjön, és csökkentheti a hulladék mennyiségét.",
      },
      {
        itemListTitle: "Fókuszáljon a nagyobb tárgyakra:",
        itemListContent:
          "A nagyobb tárgyak, mint a régi bútorok, háztartási gépek vagy elektronikai eszközök eltávolítása látványos eredményt hoz, és sok helyet felszabadít.",
      },
    ],
  },
  {
    id: 2,
    itemTitle: "Környezetbarát lomtalanítás",
    itemIntro:
      "A környezettudatos megközelítés a lomtalanítás során különösen fontos, hiszen sok tárgy újrahasznosítható.",
    itemList: [
      {
        itemListTitle: "Újrahasznosítás:",
        itemListContent:
          "A feleslegessé vált, de még használható tárgyakat juttassa el olyan helyekre, ahol újrahasznosíthatják őket. Például elektronikai hulladékot a megfelelő gyűjtőhelyre vigye, a még használható bútorokat pedig adományozhatja.",
      },
      {
        itemListTitle: "Elektronikai eszközök kezelése:",
        itemListContent:
          "Az elektronikai hulladék különösen veszélyes lehet a környezetre, ezért fontos, hogy ezeket a megfelelő módon és helyre szállítsa.",
      },
    ],
  },
  {
    id: 3,
    itemTitle: "Nagyobb projektek lomtalanítása",
    itemIntro:
      "Ha egy nagyobb projektbe kezd, mint például egy teljes ház vagy iroda lomtalanítása, érdemes előre megtervezni a lépéseket.",
    itemList: [
      {
        itemListTitle: "Szállítás előkészítése:",
        itemListContent:
          "Ha sok nagyobb tárgyat kell elszállítani, előre érdemes gondoskodni a megfelelő járműről és időpontról. Ezzel elkerülheti, hogy a dolgok túl sokáig maradjanak a helyszínen.",
      },
      {
        itemListTitle: "Helyszíni átvizsgálás:",
        itemListContent:
          "Mielőtt lomtalanításba kezd, érdemes alaposan átvizsgálni a helyszínt, hogy pontosan felmérje, mi az, amit el kell szállítani.",
      },
    ],
  },
  {
    id: 4,
    itemTitle: "Tervezze meg a logisztikát",
    itemIntro:
      "A lomtalanítás során gyakran sok mindent kell mozgatni és szállítani, ezért a logisztikai tervezés kulcsfontosságú.",
    itemList: [
      {
        itemListTitle: "Időpont egyeztetés:",
        itemListContent:
          "Győződjön meg róla, hogy az elszállítási időpontot megfelelően egyezteti, hogy minden tárgy egyszerre kerülhessen elszállításra.",
      },
      {
        itemListTitle: "Szabályok betartása:",
        itemListContent:
          "Ellenőrizze, hogy a lomtalanítás során betartja-e az adott terület hulladékkezelési szabályait. Néhány helyen speciális eljárások vonatkoznak a veszélyes hulladékokra vagy nagyobb tárgyakra.",
      },
    ],
  },
];
